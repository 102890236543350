import React, { useState, useEffect } from 'react';
import MaskedInput from 'antd-mask-input';

import LogoRodape from '@assets/Login/sociallis-logo.png';

import { LockOutlined, PixOutlined, CreditCard, PhoneOutlined, CalendarMonthOutlined, CurrencyExchangeTwoTone} from '@mui/icons-material/';

import { Grid } from '@material-ui/core';
import { Form, Input, Spin, Radio, Select as SelectAntd, Switch, Checkbox} from 'antd';

import Agradecimento from '@src/pages/Agradecimento';

import ButtonComponent from '@components/Shared/Buttons/Button';

import { TitlePage, SubTitle, InterTitle} from '@styles/styles.global';
import InvisibleContent from '@components/Shared/InvisibleContent';
import Button from '@components/Shared/Buttons/Button';

import '@styles/AntStyles.css';
import { addDays } from 'date-fns';

import MultiProgress from 'react-multi-progress';
import { Progress } from 'rsuite';

import Logo from '@assets/Login/Logotipo_horizontal_principal.png'

import UFs from '@components/Shared/BuscaEstados/UF2.json';

import Service, {
  IRequestCreatePessoa, IRequestCreatePessoaCredito, IRequestCreatePessoaDebito
} from './services';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { validaCPFandCNPJ } from '@utils/Validators';
import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import { isReal, ClearString } from '@utils/Masks';


import { mdiBarcodeScan } from '@mdi/js';
import Icon from '@mdi/react';
import useDebounce from '@hooks/useDebounce';

const DATA = addDays(new Date(), 0).toISOString().split('T')[0];

const progressoPix = 7;
const progressoBoleto = 9;
const progressoCC = 15;
const progressoDA = 15;

interface IEstado {
  id: string | number;
  label: string;
}

interface IConvenio {
  id: number;
  descricao: string;
}

interface IOperacao {
  id: number;
  descricao: string;
  codigo_operacao: string;
}

const Principal: React.FC = () => {

  const [formPessoaRef] = Form.useForm();

  const [loadingCreatePessoaCredito, setLoadingCreatePessoaCredito] = useState(false);
  const [loadingCreatePessoaPix, setLoadingCreatePessoaPix] = useState(false);
  const [loadingCreatePessoaBoleto, setLoadingCreatePessoaBoleto] = useState(false);
  const [loadingCreatePessoaDebito, setLoadingCreatePessoaDebito] = useState(false);
  const [loadingCreatePessoaTeste, setLoadingCreatePessoaTeste] = useState(false);

  const [loading, setLoading] = useState(false);
  const [valorDoacao, setValorDoacao] = useState(false);

  const [formaContribPix, setFormaContribPix] = useState(false);
  const [formaContribCredito, setFormaContribCredito] = useState(false);
  const [formaContribBoleto, setFormaContribBoleto] = useState(false);
  const [formaContribDebito, setFormaContribDebito] = useState(false);


  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');

  const [valorModifyValorDebito, setValorModifyValorDebito] =
  useState<number>(0);

  const [campo1, setCampo1] =useState<number>(0);
  const [campo2, setCampo2] = useState<number>(0);
  const [campo3, setCampo3] = useState<number>(0);
  const [campo4, setCampo4] = useState<number>(0);
  const [campo5, setCampo5] = useState<number>(0);
  const [campo6, setCampo6] = useState<number>(0);
  const [campo7, setCampo7] = useState<number>(0);

  const [campo8, setCampo8] = useState<number>(0);
  const [campo9, setCampo9] = useState<number>(0);

  const [campo10, setCampo10] = useState<number>(0);
  const [campo11, setCampo11] = useState<number>(0);
  const [campo12, setCampo12] = useState<number>(0);
  const [campo13, setCampo13] = useState<number>(0);
  const [campo14, setCampo14] = useState<number>(0);
  const [campo15, setCampo15] = useState<number>(0);

  const [campo16, setCampo16] = useState<number>(0);
  const [campo17, setCampo17] = useState<number>(0);
  const [campo18, setCampo18] = useState<number>(0);
  const [campo19, setCampo19] = useState<number>(0);
  const [campo20, setCampo20] = useState<number>(0);
  const [campo21, setCampo21] = useState<number>(0);

  const [fraseDoacao1, setFraseDoacao1] = useState(false);
  const [fraseDoacao2, setFraseDoacao2] = useState(false);
  const [fraseDoacao3, setFraseDoacao3] = useState(false);
  const [fraseDoacao4, setFraseDoacao4] = useState(false);
  const [fraseDoacao5, setFraseDoacao5] = useState(false);
  const [fraseDoacao6, setFraseDoacao6] = useState(false);
  const [fraseDoacao7, setFraseDoacao7] = useState(false);
  const [fraseDoacao8, setFraseDoacao8] = useState(false);
  const [fraseDoacao9, setFraseDoacao9] = useState(false);
  const [fraseDoacao10, setFraseDoacao10] = useState(false);

  const [confDadosPessoais, setConfDadosPessoais] = useState(false);
  const [confDadosValorFormaPag, setConfDadosValorFormaPag] = useState(false);

  const [valorDoacaoInputResposta, setValorDoacaoInputResposta] = useState('');
  const [valorDoacaoInput, setValorDoacaoInput] = useState('');

  const [loadingCEP, setLoadingCEP] = useState(false);
  const [uf, setUF] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [loadingCEP2, setLoadingCEP2] = useState(false);
  const [suggestionsEstado, setSuggestionsEstado] = useState<IEstado[]>(
    UFs.UF,
  );

  const [etapa, setEtapa] = useState(0);

  const step1 = (campo1 + campo2 + campo3 + campo4 + campo5 + campo6 + campo7) / progressoPix;
  const step2 = (campo1 + campo2 + campo3 + campo4 + campo5 + campo6 + campo7 + campo8 + campo9) / progressoBoleto;
  const step3 = (campo1 + campo2 + campo3 + campo4 + campo5 + campo6 + campo7 + campo8 + campo9 + campo10 + campo11 +
    campo12 + campo13 + campo14 + campo15) / progressoCC;

  const step4 = (campo1 + campo2 + campo3 + campo4 + campo5 + campo6 + campo7 + campo8 + campo9 + campo16 +
    campo17 + campo18 + campo19 + campo20 + campo21) / progressoDA;

  const [openAgradecimento, setAgradecimento] =
    useState(false);

  const [convenioSelecionada, setConvenioSelecionada] =
    useState('');

  const [convenio, setConvenio] = useState<IConvenio[]>([
    { id: 2, descricao: 'BANCO DO BRASIL' },
    { id: 6, descricao: 'BANCO SANTANDER S.A.' },
    { id: 7, descricao: 'BANCO BRADESCO S.A.' },
    { id: 8, descricao: 'CAIXA ECONOMICA FEDERAL' },
    { id: 9, descricao: 'BANCO ITAÚ UNIBANCO S.A.' },
  ]);

  const [operacaoCaixa, setOperacaoCaixa] = useState<IOperacao[]>([
    { id: 1, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '001' },
    { id: 2, descricao: 'Cta. Simples - P.Física', codigo_operacao: '002' },
    { id: 3, descricao: 'Cta. Corrente - P.Jurídica', codigo_operacao: '003' },
    { id: 4, descricao: 'Entidades Públicas', codigo_operacao: '006' },
    { id: 5, descricao: 'Dep. Inst. Financeiras', codigo_operacao: '007' },
    { id: 6, descricao: 'Consignação em pagamento', codigo_operacao: '011' },
    { id: 7, descricao: 'Poupança - P.Física', codigo_operacao: '013' },
    { id: 8, descricao: 'Conta Benefício', codigo_operacao: '018' },
    { id: 9, descricao: 'Poupança - P.Jurídica', codigo_operacao: '022' },
    { id: 10, descricao: 'Conta Eletrônica', codigo_operacao: '023' },
    { id: 11, descricao: 'Poupança-Créd Imobiliário', codigo_operacao: '028' },
    { id: 12, descricao: 'Dep. Lotéricos', codigo_operacao: '043' },
  ]);

  const [operacaoSantander, setOperacaoSantamder] = useState<IOperacao[]>([
    { id: 13, descricao: 'Operação 001', codigo_operacao: '001' },
    { id: 14, descricao: 'Operação 002', codigo_operacao: '002' },
    { id: 15, descricao: 'Operação 003', codigo_operacao: '003' },
    { id: 16, descricao: 'Operação 005', codigo_operacao: '005' },
    { id: 17, descricao: 'Conta salário', codigo_operacao: '060' },
    { id: 18, descricao: 'Poupança', codigo_operacao: '071' },
    { id: 19, descricao: 'Cta. Corrente - P.Física', codigo_operacao: '092' },
  ]);


  const filterCheckTipoConvenio = (descricao: any) => {
    const forma = convenio.filter(x => x.descricao === descricao);

    if (forma.length > 0) setConvenioSelecionada(forma[0].descricao);
  };

  const HandleChangeEtapa = (value: number) => {
    setEtapa(value);
  };


  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };


  async function onSubmitTeste(values: any) {

    setLoadingCreatePessoaTeste(true);
    setLoading(true);
    console.log(values);

    const services = new Service();
    const createPessoaCredito: IRequestCreatePessoaCredito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === undefined
        ?false
        :true,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: checked,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'pai_eterno'
      },
      campanha:{
        descricao: 'teste',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      }
    };

    console.log('entrou aqui')


    setLoadingCreatePessoaTeste(false);

    //window.location.reload();

   // console.log('efjdlkf0', loadingCreatePessoaTeste);
  }

  async function onSubmitCredito(values: any) {

    setLoadingCreatePessoaCredito(true);
    setLoading(true);
    console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaCredito: IRequestCreatePessoaCredito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === undefined
        ?false
        :true,
        boleto: values.boleto === false,
        pix: values.pix  === false,
        recorrente: checked,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'pai_eterno'
      },
      campanha:{
        descricao: 'teste',
      },
      cartao: {
        number: values.number,
        verification_value: values.verification_value,
        first_name: values.first_name,
        last_name: values.last_name,
        month: values.month,
        year: values.year,
      }
    };

    console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaCredito(createPessoaCredito);

    if (!error) {
      //window.alert("Cadastrado com sucesso! O Santuário Pai Eterno agradece sua doação!!");
      await(setAgradecimento(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaCredito(false);

    //window.location.reload();
  }

   async function onSubmitPix(values: any) {

    setLoadingCreatePessoaPix(true);
    setLoading(true);
    console.log(values);

    const services = new Service();

    //teste

    const createPessoaPix: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === false,
        boleto: values.boleto === false,
        pix: values.pix === undefined
        ?false
        :true,
        recorrente: checked,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: values.cep,
        uf: values.uf,
      },
      entidade: {
        nome: 'pai_eterno'
      },
      campanha:{
        descricao: 'teste',
      }
    };

    console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaPix);

    if (!error) {
      window.open(response);
      await(setAgradecimento(true));


    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");
      //await(setAgradecimento(true));
    }

    setLoadingCreatePessoaPix(false);

    //window.location.reload();
  }


  async function onSubmitBoleto(values: any){

    setLoadingCreatePessoaBoleto(true);
    setLoading(true);
    console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaBoleto: IRequestCreatePessoa = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
      },
      doacao: {
        valor: (valorModifyValorDebito) / 100 * 100,

        cartao: values.cartao === false,
        boleto: values.boleto === undefined
        ?false
        :true,
        pix: values.pix === false,
        recorrente: checked,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'pai_eterno'
      },
      campanha:{
        descricao: 'teste',
      }
    };

    console.log('entrou aqui')

    const { error, response } = await services.CreatePessoa(createPessoaBoleto);

    if (!error) {
      window.open(response);
      await(setAgradecimento(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaBoleto(false);

    //window.location.reload();
  }

  async function onSubmitDebito(values: any){

    setLoadingCreatePessoaDebito(true);
    setLoading(true);
    console.log(values);

    const services = new Service();

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }
    const cepLimpo = limparValor(values.cep);

    const createPessoaDebito: IRequestCreatePessoaDebito = {
      pessoa: {
        nome: values.nome,
        cpfcnpj: values.cpfcnpj,
        email: values.email,
        telefone: values.telefone,
        data_nascimento: values.data_nascimento,
      },
      endereco: {
        descricao: values.descricao,
        numero: values.numero,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        cep: cepLimpo,
        uf: values.uf,
      },
      entidade: {
        nome: 'pai_eterno'
      },
      campanha:{
        descricao: 'teste',
      },
      debito_automatico: {
        convenio: values.convenio,
        vencimento: values.vencimento,
        numero_agencia: values.numero_agencia,
        digito_agencia: values.digito_agencia,
        numero_conta: values.numero_conta,
        digito_conta: values.digito_conta,
        operacao: values.operacao,
        valor: (valorModifyValorDebito) / 100 * 100,
      }
    };

    console.log('entrou aqui')

    const { error, response } = await services.CreatePessoaDebito(createPessoaDebito);

    if (!error) {
      //window.open(response);
      await(setAgradecimento(true));

    } else {
      window.alert( "Erro - Sem contato com a base de dados. Por favor tente novamente ou entre em contato com a central de relacionamento!");

    }

    setLoadingCreatePessoaDebito(false);

    //window.location.reload();
  }

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();

    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP2(true);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      formPessoaRef.setFieldsValue({
        descricao: response.logradouro,
        bairro: response.bairro,
        uf: response.uf,
        cidade: response.localidade,
      });
    }
  };

  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const onChangeDocumento = (e: any) => {
    const value = e.target.value;

    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);

    formPessoaRef.setFieldsValue({
      cpfcnpj: valueModify,
    });

    if (typeDocument === 'pj') {
      formPessoaRef.setFieldsValue({
        sexo: 1,
      });
    }
  };

  const validaDocumento = (_: any, val: any) => {
    const documentoExiste = validaCPFandCNPJ(val);

    if (documentoExiste) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Digite um documento válido'));
  };


  async function compararValorDoacao(valor: any){
    setValorDoacaoInputResposta('');

    if (valor < 5){
      setValorDoacaoInputResposta('Valor mínimo a ser doado R$ 5');
      //console.log('TESTE', valor)
      //console.log('TESTE', valorDoacaoInputResposta)
      setFraseDoacao6(false);
      setFraseDoacao7(false);
      setFraseDoacao8(false);
      setFraseDoacao9(false);
      setFraseDoacao10(false);
    }

    if (valor === '20' || valor === '40' || valor === '60' || valor === '90'){
         setValorDoacaoInputResposta('Digite um valor difente dos pré definidos: "20 40 60 90"')
         setFraseDoacao6(false);
         setFraseDoacao7(false);
         setFraseDoacao8(false);
         setFraseDoacao9(false);
         setFraseDoacao10(false);
    }
  }

  const createArrayRange = (
    start: number,
    increment: number,
    length: number,
  ) => {
    let array = [];

    for (let i = start; i <= length; i += increment) {
      array.push(i);
    }

    return array;
  };


  return (
    <>
      <Grid container>
        <InvisibleContent visible={openAgradecimento === false}>
          <Grid container >
            <Grid container item lg={12} sm={12} xs={12}
              alignItems="center" justify="center" style={{paddingTop: 20}}
            >
                  <img src={Logo} style={{width: 200, height: 86}} />
            </Grid>
          </Grid>

          <Grid container >
            <Grid container item lg={12} sm={12} xs={12}
              alignItems="center" justify="center" style={{ paddingTop: 9}}
            >
              <TitlePage>Faça sua doação</TitlePage>
            </Grid>

            <Grid container item lg={12} sm={12} xs={12}
              alignItems="center" justify="center"
            >

            <InterTitle style={{fontSize: 14}}>"Deus lhe abençoe! Sua doação é fundamental para continuarmos com as obras do Pai Eterno" </InterTitle>
            </Grid>


          </Grid>


          <Grid container style={{ paddingTop: 20}}>
          <Grid container item lg={3} sm={12} md={12} xs={12}></Grid>
            <Grid container item lg={6} sm={12} md={12} xs={12}
              alignItems="center" justify="center" style={{ paddingTop: 6, paddingLeft: 25, paddingRight: 25}}
            >

              <InvisibleContent
                visible = {formaContribBoleto === false && formaContribCredito === false && formaContribPix === false && formaContribDebito === false}
              >
                <MultiProgress
                    round={false}
                    height={20}
                    //width={Math.round(getPercentage14 * 100)}
                    //W value={getPercentage14 * 100}
                    backgroundColor='#f0f0f0'
                    elements={[

                      {
                        value: step1 * 100,
                        color: "#c51f1f",
                        textColor: "white",
                        //showPercentage: true,
                        fontSize: 12,
                      },

                    ]}
                />

              </InvisibleContent>

              <InvisibleContent
                visible = { formaContribPix === true}
              >
                <MultiProgress
                    round={false}
                    height={20}
                    //width={Math.round(getPercentage14 * 100)}
                    //W value={getPercentage14 * 100}
                    backgroundColor='#f0f0f0'
                    elements={[

                      {
                        value: step1 * 100,
                        color: "#c51f1f",
                        textColor: "white",
                        //showPercentage: true,
                        fontSize: 12,
                      },

                    ]}
                />

              </InvisibleContent>


              <InvisibleContent
                visible = {formaContribBoleto === true}
              >
                <MultiProgress
                    round={false}
                    height={20}
                    //width={Math.round(getPercentage14 * 100)}
                    //W value={getPercentage14 * 100}
                    backgroundColor='#f0f0f0'
                    elements={[

                      {
                        value: step2 * 100,
                        color: "#c51f1f",
                        textColor: "white",
                        //showPercentage: true,
                        fontSize: 12,
                      },

                    ]}
                />

              </InvisibleContent>

              <InvisibleContent
                visible = {formaContribCredito === true}
              >
                <MultiProgress
                    round={false}
                    height={20}
                    //width={Math.round(getPercentage14 * 100)}
                    //W value={getPercentage14 * 100}
                    backgroundColor='#f0f0f0'
                    elements={[

                      {
                        value: step3 * 100,
                        color: "#c51f1f",
                        textColor: "white",
                        //showPercentage: true,
                        fontSize: 12,
                      },

                    ]}
                />

              </InvisibleContent>

              <InvisibleContent
                visible = {formaContribDebito=== true}
              >
                <MultiProgress
                    round={false}
                    height={20}
                    //width={Math.round(getPercentage14 * 100)}
                    //W value={getPercentage14 * 100}
                    backgroundColor='#f0f0f0'
                    elements={[

                      {
                        value: step4 * 100,
                        color: "#c51f1f",
                        textColor: "white",
                        //showPercentage: true,
                        fontSize: 12,
                      },

                    ]}
                />

              </InvisibleContent>
            </Grid>

            <Grid container item lg={3} sm={12} md={12} xs={12}></Grid>

          </Grid>


          {/* ================== FORMULÁRIO ==================== */}


          <Grid container item lg={12} sm={12} xs={12}
            justify="center" alignItems="center" style={{ marginTop: 25 }}
          >
            <Grid item lg={2} sm={1} xs={1} ></Grid>


            <Grid item lg={6} sm={12} xs={12}>
              <Form
                form={formPessoaRef}
                size="middle"
                layout="vertical"
                scrollToFirstError={true}
                //onFinish={onSubmitCredito}
                labelAlign="left"
                labelCol={{
                  span: 50,
                }}
                wrapperCol={{
                  span: 50,
                }}
                requiredMark={false}
                initialValues={{
                  nome: '',
                  cpfcnpj: '',
                  email: '',
                  descricao: '',
                  numero: '',
                  complemento: '',
                  bairro: '',
                  cidade: '',
                  cep: '',
                  uf: '',
                }}
              >
                {/* ------------------------ ETAPA DADOS PESSOAIS ---------------------------------------------- */}
                <Grid container style={{ display: etapa === 0 ? 'flex' : 'none' }}>

                  <Grid container justify="center" >
                    <InvisibleContent
                      visible={tipoDocumento === 'pf'}
                    >
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Form.Item
                          label='Nome Completo *'
                          name="nome"
                          rules={[
                            { required: true, message: '*Campo obrigatório' },
                            { min: 3, message: 'Mínimo de 3 letras' },
                            {
                              pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                              message: 'Somente letras'
                            }
                          ]}
                        >
                          <Input
                            onChange={() => {setCampo1(1); setFraseDoacao1(true)}} onInput={handleInput}
                          />
                        </Form.Item>
                      </Grid>
                    </InvisibleContent>

                    <InvisibleContent
                      visible={tipoDocumento === 'pj'}
                    >
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Form.Item
                          label='Razão Social *'
                          name="nome"
                          rules={[
                            { required: true, message: 'Campo obrigatorio' },
                            { min: 3, message: 'Minimo de 3 letras' },
                          ]}
                        >
                          <Input
                            onChange={() => {setCampo1(1); setFraseDoacao1(true)}} onInput={handleInput}
                          />
                        </Form.Item>
                      </Grid>
                    </InvisibleContent>
                  </Grid>

                  <Grid container justify="center">
                    <Grid item lg={12} sm={12}  xs={12} style={{ paddingRight: 20, paddingLeft: 20 }}>
                      <Form.Item
                        label="CPF/CNPJ *"
                        name="cpfcnpj"
                        rules={[
                          { required: true, message: '*Campo obrigatório' },
                          { min: 14, message: 'Minimo 14 caracteres' },
                          { validator: (e, a) => validaDocumento(e, a) },
                        ]}
                      >
                        <Input
                          maxLength={18}
                          onChange={(e: any) => {
                            onChangeDocumento(e);
                            setCampo2(1);
                            setFraseDoacao2(true)
                          }}

                        />
                      </Form.Item>
                    </Grid>
                  </Grid>

                  <Grid container justify="center">
                    <InvisibleContent
                      visible={tipoDocumento === 'pf'}
                    >
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Form.Item
                          label='Data de nascimento *'
                          name="data_nascimento"
                          rules={[
                            { required: true, message: '*Campo obrigatório' },
                            ]}
                          >
                          <MaskedInput
                            mask='11/11/1111'
                            max={DATA}
                            onChange={() => {setCampo3(1); setFraseDoacao3(true)}}
                            suffix={<CalendarMonthOutlined style={{ width:15, height: 15}} />}
                          />
                        </Form.Item>
                      </Grid>

                    </InvisibleContent>

                    <InvisibleContent
                      visible={tipoDocumento === 'pj'}
                    >
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Form.Item
                          label='Data de fundação*'
                          name="data_nascimento"
                          rules={[
                            { required: true, message: 'Campo obrigatorio' },
                          ]}
                        >
                          <MaskedInput
                            mask='11/11/1111'
                            max={DATA}
                            onChange={() => {setCampo3(1); setFraseDoacao3(true)}}
                            suffix={<CalendarMonthOutlined style={{ width:15, height: 15}} />}
                          />
                        </Form.Item>
                      </Grid>
                    </InvisibleContent>
                  </Grid>

                  <Grid container justify="center">
                    <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                      <Form.Item
                        label='E-mail *'
                        name="email"
                        rules={[
                          { required: true, message: '*Campo obrigatório' },
                          { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                            message: 'Digite um e-mail válido!'
                          },
                        ]}
                      >
                        <Input onChange={() => {setCampo4(1); setFraseDoacao4(true)}}/>
                      </Form.Item>
                    </Grid>
                  </Grid>


                  <Grid container justify="center">
                    <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}  >
                      <Form.Item
                        label='Celular *'
                        name="telefone"
                        rules={[
                          { required: true, message: '*Campo obrigatório' },
                        ]}
                      >
                        <MaskedInput
                          onChange={() => {setCampo5(1); setFraseDoacao5(true)}}
                          mask="(11) 11111-1111"
                          placeholder='(11)10000-0000'
                          //placeholder="Celular"
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>

                  <InvisibleContent visible = {fraseDoacao1 === false || fraseDoacao2 === false || fraseDoacao3 === false || fraseDoacao4 === false || fraseDoacao5 === false}>

                    <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10, paddingRight: 20, paddingLeft: 20}} >
                      <Form
                        form={formPessoaRef}
                        scrollToFirstError={true}
                        onFinish={onSubmitTeste}
                      >
                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaTeste}
                          type="submit"
                          //onClick={() => HandleChangeEtapa(1)}
                        >
                          Prosseguir
                        </ButtonComponent>
                      </Form>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent visible = {fraseDoacao1 === true && fraseDoacao2 === true && fraseDoacao3 === true && fraseDoacao4 === true && fraseDoacao5 === true}>

                    <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10, paddingRight: 20, paddingLeft: 20}} >

                        <ButtonComponent
                          color="secondary"
                          loading={loadingCreatePessoaTeste}
                          type="submit"
                          onClick={() => {HandleChangeEtapa(1); setConfDadosPessoais(true)}}
                        >
                          Prosseguir
                        </ButtonComponent>

                    </Grid>
                  </InvisibleContent>

                </Grid>

                {/* ------------------------ ETAPA VALOR E FORMA DE PAGAMENTO ----------------------------- */}
                <Grid container style={{ display: etapa === 1 ? 'flex' : 'none' }}>
                  <InvisibleContent visible={confDadosPessoais === true}>

                    {/* ------------------------ VALOR DOAÇÃO ---------------------------------------------- */}
                    <Grid container>


                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }}  >
                        <SubTitle> Selecione o valor da doação  </SubTitle>
                      </Grid>

                      <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                        <Form.Item
                          name='valorDoacao'
                          rules={[
                            {required: true, message: '*Campo obrigatório' },
                          ]}
                        >

                          <Grid>
                            <Form.Item name="valor" >
                              <Radio.Group>
                                <Grid container item  lg={12} sm={12} xs={12} justify="center" alignItems="center" >
                                  <Grid item container justify="center" alignItems="center" lg={3} sm={3} md={6} xs={6} style={{ paddingTop: 5}}>
                                    <Form.Item>
                                      <Radio.Button value={20} onClick={(e: any) => {setValorDoacao(e); setValorDoacaoInput('')}} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setCampo6(1); setFraseDoacao6(true); compararValorDoacao(e.target.value);; setValorDoacaoInputResposta('')}} >
                                        R$ 20
                                      </Radio.Button>
                                    </Form.Item>
                                  </Grid>

                                  <Grid item container justify="center" alignItems="center" lg={3} sm={3} md={6} xs={6} style={{ paddingTop: 5}}>
                                    <Form.Item>
                                      <Radio.Button value={40} onClick={(e: any) => {setValorDoacao(e); setValorDoacaoInput('')}} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setCampo6(1); setFraseDoacao7(true); compararValorDoacao(e.target.value); setValorDoacaoInputResposta('')}} >
                                        R$ 40
                                      </Radio.Button>
                                    </Form.Item>
                                  </Grid>


                                  <Grid item container justify="center" alignItems="center" lg={3} sm={3} md={6} xs={6} style={{ paddingTop: 5}}>
                                    <Form.Item>
                                      <Radio.Button value={60} onClick={(e: any) => {setValorDoacao(e); setValorDoacaoInput('')}} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setCampo6(1); setFraseDoacao8(true); compararValorDoacao(e.target.value); setValorDoacaoInputResposta('')}} >
                                        R$ 60
                                      </Radio.Button>
                                    </Form.Item>
                                  </Grid>

                                  <Grid item container justify="center" alignItems="center" lg={3} sm={3} md={6} xs={6} style={{ paddingTop: 5}}>
                                    <Form.Item>
                                      <Radio.Button value={90} onClick={(e: any) => {setValorDoacao(e); setValorDoacaoInput('')}} onChange={(e: any) => {setValorModifyValorDebito(e.target.value); setCampo6(1); setFraseDoacao9(true); compararValorDoacao(e.target.value); setValorDoacaoInputResposta('')}} >
                                        R$ 90
                                      </Radio.Button>
                                    </Form.Item>
                                  </Grid>

                                </Grid >

                                <InvisibleContent visible={valorDoacao != true}>

                                  <Grid style={{paddingTop: 11}}>

                                    <Grid container item lg={12} sm={12} xs={12}
                                      style={{borderRadius: 10, background:'#f0f0f0', border: '2px solid #ddd' }}
                                    >
                                      <Grid container justify="center" >
                                        <Grid item lg={12} sm={12} xs={12}>
                                          <Form.Item
                                            label='Doar outro valor'
                                          >
                                            <Radio.Button
                                              value={''}
                                              style={{ textAlign: 'left', fontSize: 16, color: '#c1c1c1' ,background: '#f9f9f9', width: '100%', height: 40, borderRadius: 4, border: '1px solid #ddd', paddingTop: 5}}
                                              onChange={(e: any) => {setValorDoacao(true);}}
                                            >
                                              Deseja doar outro valor? Digite aqui
                                            </Radio.Button>
                                          </Form.Item>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </InvisibleContent>
                              </Radio.Group>

                              <InvisibleContent visible={valorDoacao === true}>

                                <Grid style={{paddingTop: 11}}>

                                  <Grid container item lg={12} sm={12} xs={12}
                                    style={{borderRadius: 10, background:'#f0f0f0', border: '2px solid #ddd' }}
                                  >
                                    <Grid container justify="center" >
                                      <Grid item lg={12} sm={12} xs={12}>
                                        <Form.Item
                                        label='Doar outro valor'
                                          rules={[
                                            {pattern: /^[0-9]+$/, message: 'Por favor digite somente números!'},
                                          ]}
                                        >
                                          <InvisibleContent visible={valorDoacaoInputResposta === ''}>
                                          <Input
                                            style={{ fontSize: 16}}
                                            type={"number"}
                                            value={valorDoacaoInput}
                                            //defaultValue={valorDoacaoInput}
                                            inputMode='numeric'
                                            placeholder='Deseja doar outro valor? Digite aqui'
                                            onChange={(e: any) => {
                                              setValorModifyValorDebito(e.target.value);
                                              setCampo6(1);
                                              setFraseDoacao10(true);
                                              compararValorDoacao(e.target.value);
                                              setValorDoacaoInput(e.target.value)


                                            }}
                                          />
                                          </InvisibleContent>

                                          <InvisibleContent visible={valorDoacaoInputResposta != ''}>
                                          <Input
                                            style={{ fontSize: 16, borderColor: '#ff4d4f'}}
                                            type={"number"}
                                            value={valorDoacaoInput}
                                            //defaultValue={valorDoacaoInput}
                                            inputMode='numeric'
                                            placeholder='Deseja doar outro valor? Digite aqui2222'
                                            onChange={(e: any) => {
                                              setValorModifyValorDebito(e.target.value);
                                              setCampo6(1);
                                              setFraseDoacao10(true);
                                              compararValorDoacao(e.target.value);
                                              setValorDoacaoInput(e.target.value)


                                            }}
                                          />
                                          </InvisibleContent>

                                        </Form.Item>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <p style={{color: "#ff4d4f"} }>
                                    {valorDoacaoInputResposta}
                                  </p>
                                </Grid>
                              </InvisibleContent>
                            </Form.Item>
                          </Grid>
                        </Form.Item>
                      </Grid>
                    </Grid>


                    {/* ------------------------ DOAÇÃO RECORRENTE ---------------------------------------------- */}
                    <Grid container style={{ paddingLeft: 24, paddingRight: 24}} >
                      <Grid container item lg={12} sm={12} xs={12} justify="center" alignItems="center"
                        style={{paddingTop: 10, paddingBottom: 20, borderRadius: 10, background:'#f9f9f9'}}
                      >

                        <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }}  >
                        <InterTitle> Frequência da doação </InterTitle>
                      </Grid>

                      <Grid item container lg={12} sm={12} xs={12} justify="center" style={{ paddingTop: 15}} >

                        <InvisibleContent  visible={checked === false} >
                          <Grid item container lg={12} sm={12} xs={12}>
                            <p style={{fontSize: 14, color: '#666',  paddingLeft: 24, paddingRight: 24}}> <strong>Sua contribuição mensal é vital para continuarmos nossas obras sociais.</strong>  Você pode cancelar a qualquer momento. Ao selecionar <strong>Contribuir mensalmente</strong>, você autoriza realizar a sua doação mensal de forma automática.</p>
                          </Grid>
                        </InvisibleContent>

                        <InvisibleContent  visible={checked === true} >
                          <Grid item container lg={12} sm={12} xs={12}>
                            <p style={{fontSize: 15, color: '#228b22',  paddingLeft: 24, paddingRight: 24}}> Doação mensal ativada. Você estará doando e contribuindo mensalmente e poderá cancelar quando quiser através de nossa Central de Relacionamento: (62) 3506-9800.</p>
                          </Grid>
                        </InvisibleContent>

                        <Grid container item lg={12} md={12} sm={12} xs={12} justify="center" alignItems="center">

                          <Grid container item lg={5} md={5} sm={5} xs={12} justify="center" alignItems="center">
                            <strong style={{fontSize: '1.33em'}} >Contribuir uma única vez </strong>
                          </Grid>

                          <Grid container item lg={1} md={1} sm={1} xs={12} justify="center" alignItems="center">
                            <Form.Item
                              name="recorrente"
                              //valuePropName="checked"
                            >
                              <Switch
                                //checked={checked}
                                onChange={() => setChecked(!checked)}
                              />
                            </Form.Item>
                          </Grid>

                          <Grid container item lg={5} md={5} sm={5} xs={12} justify="center" alignItems="center">
                          <strong style={{fontSize: '1.33em'}}> Contribuir mensalmente </strong>
                          </Grid>

                        </Grid>

                        </Grid>
                      </Grid>
                    </Grid>


                    {/* ------------------------ FORMA DE PAGAMENTO -------------------------------------------------- */}

                    <Grid container>

                      <Grid item lg={12} sm={12} xs={12}  alignItems="center" justify="center"style={{ paddingLeft: 24, paddingRight: 24 }}  >
                        <SubTitle> Selecione a forma de pagamento </SubTitle>
                      </Grid>

                      <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" >
                        <Form.Item
                          name='doacao'
                          rules={[
                            {required: true, message: '*Campo obrigatório' },
                          ]}
                        >

                          <Radio.Group>
                            <Grid container item  lg={12} sm={12} xs={12} justify="center" alignItems="center" >
                              <Grid item container lg={3} md={3} sm={3} xs={12} justify="center" alignItems="center" >
                                <Form.Item  name='boleto' valuePropName="checked">
                                    <Radio.Button name='boleto' value="boleto" style={{  width: 150, height: 120, paddingTop: 25, backgroundColor: '#fff '}}
                                      onChange={() => setFormaContribBoleto(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribCredito(false); setFormaContribDebito(false); setCampo7(1);}}
                                    >
                                      <Icon path={mdiBarcodeScan} size={1.2} /> <p> Boleto </p>
                                    </Radio.Button>
                                </Form.Item>
                              </Grid>

                              <Grid item  container lg={3} md={3} sm={3} xs={12} justify="center" alignItems="center">
                                <Form.Item  name='pix' valuePropName="checked">
                                  <Radio.Button name='pix' value="pix" style={{  width: 150, height: 120, paddingTop: 25, backgroundColor: '#fff '}}
                                    onChange={() => setFormaContribPix(true)} onClick={(e: any) => {setFormaContribCredito(false); setFormaContribBoleto(false); setFormaContribDebito(false); setCampo7(1);}}
                                  >
                                    <PixOutlined style={{height: 30, width: 30}}/>  <p> Pix </p>
                                  </Radio.Button>
                                </Form.Item>
                              </Grid>

                              <Grid item container lg={3} md={3} sm={3} xs={12} justify="center" alignItems="center">
                                <Form.Item  name='cartao' valuePropName="checked">
                                  <Radio.Button  name='cartao' value="cartao" style={{  width: 150, height: 120, paddingTop: 15, backgroundColor: '#fff '}}
                                    onChange={() => setFormaContribCredito(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribDebito(false); setCampo7(1);}}
                                  >
                                    <CreditCard style={{height: 30, width: 30}}/> <p> Cartão de crédito </p>
                                  </Radio.Button>
                                </Form.Item>
                              </Grid>

                              <Grid item container lg={3} md={3} sm={3} xs={12} justify="center" alignItems="center">
                                <Form.Item  name='debito' valuePropName="checked">
                                  <Radio.Button  name='debito' value="debito" style={{  width: 150, height: 120, paddingTop: 15, backgroundColor: '#fff '}}
                                    onChange={() => setFormaContribDebito(true)} onClick={(e: any) => {setFormaContribPix(false); setFormaContribBoleto(false); setFormaContribCredito(false); setCampo7(1);}}
                                  >
                                    <CurrencyExchangeTwoTone style={{height: 25, width: 25}}/> <p> Débito automático </p>
                                  </Radio.Button>
                                </Form.Item>
                              </Grid>

                            </Grid >
                          </Radio.Group>
                        </Form.Item>
                      </Grid>
                    </Grid>

                    {/* ------------------------ BOTÃO PARA VALIDAR PREENCHIMENTO E DOAÇÃO PIX ------------------- */}

                    <Grid container>

                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >

                        <InvisibleContent
                        visible={formaContribPix === false && formaContribBoleto === false && formaContribCredito === false && formaContribDebito === false && fraseDoacao6 === false && fraseDoacao7 === false && fraseDoacao8 === false && fraseDoacao9 === false  && fraseDoacao10 === false}>

                          <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}}>
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitTeste}
                            >
                              <ButtonComponent
                                color="secondary"
                                loading={loadingCreatePessoaTeste}
                                type="submit"
                                //onClick={() => HandleChangeEtapa(1)}
                              >
                                Prosseguir
                              </ButtonComponent>
                            </Form>

                          </Grid>

                        </InvisibleContent>

                        <InvisibleContent visible={fraseDoacao6 === true || fraseDoacao7 === true || fraseDoacao8 || fraseDoacao9 === true || fraseDoacao10 === true}>
                          <InvisibleContent visible={formaContribBoleto === false && formaContribCredito === false && formaContribPix === false && formaContribDebito === false}>

                            <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}}>
                              <Form
                                form={formPessoaRef}
                                scrollToFirstError={true}
                                onFinish={onSubmitTeste}
                              >
                                <ButtonComponent
                                  color="secondary"
                                  loading={loadingCreatePessoaTeste}
                                  type="submit"
                                  //onClick={() => HandleChangeEtapa(1)}
                                >
                                  Prosseguir
                                </ButtonComponent>
                              </Form>
                            </Grid>

                          </InvisibleContent>
                        </InvisibleContent>

                        <InvisibleContent visible={formaContribBoleto === true || formaContribCredito === true || formaContribDebito === true}>
                          <InvisibleContent visible={fraseDoacao6 === false && fraseDoacao7 === false && fraseDoacao8 === false && fraseDoacao9 === false && fraseDoacao10 === false} >

                            <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}}>
                              <Form
                                form={formPessoaRef}
                                scrollToFirstError={true}
                                onFinish={onSubmitTeste}
                              >
                                <ButtonComponent
                                  color="secondary"
                                  loading={loadingCreatePessoaTeste}
                                  type="submit"
                                  //onClick={() => HandleChangeEtapa(1)}
                                >
                                  Prosseguir
                                </ButtonComponent>
                              </Form>
                            </Grid>

                          </InvisibleContent>
                        </InvisibleContent>

                        <InvisibleContent visible={fraseDoacao6 === true || fraseDoacao7 === true || fraseDoacao8 || fraseDoacao9 === true || fraseDoacao10 === true}>
                          <InvisibleContent visible={formaContribBoleto === true || formaContribCredito === true || formaContribDebito === true}>

                            <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}}>
                              <ButtonComponent
                                color="secondary"
                                loading={loadingCreatePessoaTeste}
                                type="submit"
                                onClick={() => {HandleChangeEtapa(2); setConfDadosValorFormaPag(true)}}
                              >
                                Prosseguir
                              </ButtonComponent>
                            </Grid>


                          </InvisibleContent>
                        </InvisibleContent>


                        {/* ------------------------ REALIZAR DOAÇÃO PIX  ----------------------------- */}


                        <InvisibleContent
                          visible={formaContribPix === true}
                        >
                          <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitPix}
                            >
                              <ButtonComponent
                                color="secondary"
                                loading={loadingCreatePessoaPix}
                                type="submit"
                              >
                                Realizar doação
                              </ButtonComponent>
                            </Form>
                          </Grid>
                        </InvisibleContent>

                        <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                          <Button
                            color='default'
                            onClick={() => HandleChangeEtapa(0)}
                            style={{color: '#000', fontSize: '16px', backgroundColor: 'transparent'}}
                          >
                            Voltar
                          </Button>
                        </Grid>

                      </Grid>
                    </Grid>

                  </InvisibleContent>
                </Grid>

                {/* ------------------------ ETAPA VALOR E FORMA DE PAGAMENTO ----------------------------- */}
                <Grid container style={{ display: etapa === 2 ? 'flex' : 'none' }}>
                  <InvisibleContent visible={confDadosValorFormaPag === true}>

                    {/* ------------------ CADASTRAR CARTÃO DE CRÉDITO ------------------------- */}
                    <Grid container>
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >
                        <InvisibleContent visible={formaContribCredito === true}>

                        <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }}  >
                        <SubTitle> Dados do cartão de crédito</SubTitle>
                      </Grid>


                          <Grid container  style={{borderRadius: 10, border: `2px solid #f9f9f9 `,}} >
                            <Grid item lg={12} sm={12}  xs={12} >
                              <Form.Item
                                label="Número do cartão *"
                                name="number"
                                rules={[
                                  { required: true, message: '*Campo obrigatório' },
                                  {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                ]}
                              >
                                <Input

                                type={"text"}
                                  inputMode='numeric'
                                  minLength={13} maxLength={16}  onChange={() => setCampo10(1)}
                                  placeholder='1111.1111.1111.1111'/>
                              </Form.Item>
                            </Grid>


                            <Grid item lg={12} sm={12} xs={12}>
                              <Form.Item
                                label='Nome impresso cartão *'
                                name="first_name"
                                rules={[
                                  { required: true, message: '*Campo obrigatório' },
                                  {
                                    pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                                    message: 'Somente letras'
                                  }
                                ]}
                              >
                                <Input onChange={(e: any) => {setCampo11(1);}}/>
                              </Form.Item>
                            </Grid>


                            <Grid item lg={12} sm={12} xs={12}>
                              <Form.Item
                                label='Sobrenome impresso cartão *'
                                name="last_name"
                                rules={[
                                  { required: true, message: '*Campo obrigatório' },
                                  {
                                    pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                                    message: 'Somente letras'
                                  }
                                ]}
                              >
                                <Input onChange={() => setCampo12(1)} />
                              </Form.Item>
                            </Grid>


                            <Grid container>
                              <Grid item lg={4} sm={4} xs={12}>
                                <Form.Item
                                  label='Mês *'
                                  name="month"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                  ]}
                                >
                                  <MaskedInput
                                    mask='11'
                                    //type={"number"}
                                    placeholder='MM'
                                    inputMode='numeric'

                                    maxLength={2} minLength={2} onChange={() => setCampo13(1)} />
                                </Form.Item>
                              </Grid>


                              <Grid item lg={4} sm={4} xs={12}>
                                <Form.Item
                                  label='Ano *'
                                  name="year"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                  ]}
                                >
                                  <MaskedInput
                                  maxLength={4} minLength={4}
                                    mask='1111'
                                    //type={"number"}
                                    inputMode='numeric'
                                    placeholder='YYYY'
                                    onChange={() => setCampo14(1)} />
                                </Form.Item>
                              </Grid>


                              <Grid item lg={4} sm={4} xs={12}>
                                <Form.Item
                                  label='CVV *'
                                  name="verification_value"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                  ]}
                                >
                                  <MaskedInput
                                    mask='111'
                                    maxLength={3} minLength={3}
                                    placeholder='000'
                                    //type={"number"}
                                    inputMode='numeric'
                                    onChange={() => setCampo15(1)}
                                  />

                                </Form.Item>
                              </Grid>
                            </Grid>


                            <Grid container>
                              <img src="https://s3-sa-east-1.amazonaws.com/storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/cc-icons.e8f4c6b4db3cc0869fa93ad535acbfe7.png" alt="Visa, Master, Diners. Amex" />
                                <a id="iugu-btn" href="http://iugu.com" target="_blank" rel="noopener noreferrer">
                              <img src="https://s3-sa-east-1.amazonaws.com/storage.pupui.com.br/9CA0F40E971643D1B7C8DE46BBC18396/assets/payments-by-iugu.1df7caaf6958f1b5774579fa807b5e7f.png" alt="Pagamentos por Iugu"  /></a>
                            </Grid>
                          </Grid>
                        </InvisibleContent>
                      </Grid>
                    </Grid>


                    {/* ------------------ CADASTRAR DÉBITO AUTOMÁTICO  ------------------------- */}
                    <Grid container>
                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >
                        <InvisibleContent visible={formaContribDebito === true}>

                          <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }}  >
                          <SubTitle> Dados conta bancária</SubTitle>
                          </Grid>

                          <Grid container  style={{borderRadius: 10, border: `2px solid #f9f9f9 `,}} >
                            <Grid item lg={12} sm={12}  xs={12} >
                              <Form.Item
                                label="Convênio *"
                                name="convenio"
                                rules={[
                                  { required: true, message: '*Campo obrigatório' },
                                 // {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                ]}
                              >
                                <SelectAntd
                                  onSelect={(e: any) => filterCheckTipoConvenio(e)}
                                  onChange={() => setCampo16(1)}
                                >
                                  {convenio.map(item => (
                                    <SelectAntd.Option key={item.id} value={item.descricao}>
                                      {item.descricao}
                                    </SelectAntd.Option>
                                  ))}
                                </SelectAntd>
                              </Form.Item>
                            </Grid>

                            <Grid container>
                              <Grid item lg={8} sm={8} xs={8}>
                                <Form.Item
                                  label='Número agência *'
                                  name="numero_agencia"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    { pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                  ]}
                                >
                                  <MaskedInput
                                      mask='1111'
                                      maxLength={3} minLength={3}
                                      placeholder='0000'
                                      inputMode='numeric'
                                      onChange={() => setCampo17(1)}
                                    />
                                </Form.Item>
                              </Grid>

                              <Grid item lg={4} sm={4} xs={4}>
                                <Form.Item
                                  label='Dígito agência *'
                                  name="digito_agencia"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    { pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},
                                  ]}
                                >
                                  <Input
                                    maxLength={1}
                                    minLength={1}
                                    onChange={() => setCampo18(1)}
                                    />
                                </Form.Item>
                              </Grid>
                            </Grid>

                            <Grid container>
                              <Grid item lg={8} sm={8} xs={8}>
                                <Form.Item
                                  label='Número conta *'
                                  name="numero_conta"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    {  pattern: /^[0-9]+$/, message: 'Somente números!'},
                                  ]}
                                >
                                  <Input
                                    inputMode='numeric'
                                    onChange={() => setCampo19(1)}
                                  />
                                </Form.Item>
                              </Grid>

                              <Grid item lg={4} sm={4} xs={4}>
                                <Form.Item
                                  label='Número conta *'
                                  name="digito_conta"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' },
                                    {  pattern: /^[a-zA-Z0-9]+$/, message: "Somente números ou letras"},
                                  ]}
                                >
                                  <Input
                                    onChange={() => setCampo20(1)}
                                  />
                                </Form.Item>
                              </Grid>
                            </Grid>

                            <InvisibleContent
                              visible={!convenioSelecionada
                              .toLocaleUpperCase()
                              .includes('SANTANDER') && !convenioSelecionada
                              .toLocaleUpperCase()
                              .includes('CAIXA')  }
                            >

                            <Grid item lg={12} sm={12}  xs={12} >
                              <Form.Item
                                label="Vencimento *"
                                name="vencimento"
                                rules={[
                                  { required: true, message: '*Campo obrigatório' },
                                 // {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                ]}
                              >
                                <SelectAntd  onChange={() => setCampo21(1)}>
                                  {createArrayRange(1, 1, 31).map(i => (
                                    <SelectAntd.Option key={i} value={i}>
                                      {i}
                                    </SelectAntd.Option>
                                  ))}

                                </SelectAntd>
                              </Form.Item>
                            </Grid>
                            </InvisibleContent>

                            <InvisibleContent
                              visible={convenioSelecionada
                              .toLocaleUpperCase()
                              .includes('SANTANDER') }
                            >
                              <Grid container>
                                <Grid item lg={8} sm={8} xs={8} justify="center">
                                  <Form.Item
                                    name="operacao"
                                    label="Operação *"
                                    rules={[
                                      {
                                        required: true, message: 'Campo obrigatório'
                                      },
                                    ]}
                                  >
                                    <SelectAntd placeholder='Operação'>
                                      {operacaoSantander.map(item => (
                                        <SelectAntd.Option
                                          key={item.codigo_operacao}
                                          value={item.codigo_operacao}
                                        >
                                          {item.codigo_operacao + ' - ' + item.descricao}
                                        </SelectAntd.Option>
                                      ))}
                                    </SelectAntd>
                                  </Form.Item>
                                </Grid>

                                <Grid item lg={4} sm={4}  xs={4} >
                                  <Form.Item
                                    label="Vencimento *"
                                    name="vencimento"
                                    rules={[
                                      { required: true, message: '*Campo obrigatório' },
                                    // {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                    ]}
                                  >
                                    <SelectAntd  onChange={() => setCampo21(1)}>
                                      {createArrayRange(1, 1, 31).map(i => (
                                        <SelectAntd.Option key={i} value={i}>
                                          {i}
                                        </SelectAntd.Option>
                                      ))}
                                    </SelectAntd>
                                  </Form.Item>
                                </Grid>
                              </Grid>
                            </InvisibleContent>

                            <InvisibleContent
                              visible={convenioSelecionada
                                .includes('CAIXA') }
                            >
                              <Grid container>
                                <Grid item lg={8} sm={8} xs={8} justify="center">
                                  <Form.Item
                                    name="operacao"
                                    label="Operação *"
                                    rules={[
                                      {
                                        required: true, message: 'Campo obrigatório'
                                      },
                                    ]}
                                  >
                                    <SelectAntd placeholder='Operação'>
                                      {operacaoCaixa.map(item => (
                                        <SelectAntd.Option
                                          key={item.codigo_operacao}
                                          value={item.codigo_operacao}
                                        >
                                          {item.codigo_operacao + ' - ' + item.descricao}
                                        </SelectAntd.Option>
                                      ))}
                                    </SelectAntd>
                                  </Form.Item>
                                </Grid>

                                <Grid item lg={4} sm={4}  xs={4} >
                                  <Form.Item
                                    label="Vencimento *"
                                    name="vencimento"
                                    rules={[
                                      { required: true, message: '*Campo obrigatório' },
                                    // {  pattern: /^[0-9]+$/, message: 'Digite um valor válido!'},
                                    ]}
                                  >
                                    <SelectAntd  onChange={() => setCampo21(1)}>
                                      {createArrayRange(1, 1, 31).map(i => (
                                        <SelectAntd.Option key={i} value={i}>
                                          {i}
                                        </SelectAntd.Option>
                                      ))}
                                    </SelectAntd>
                                  </Form.Item>
                                </Grid>
                              </Grid>
                            </InvisibleContent>

                          </Grid>
                        </InvisibleContent>
                      </Grid>
                    </Grid>


                    {/* ------------------------ ENDEREÇO BOLETO, CARTÃO DE CRÉDITO E DÉBITO AUTOMÁTICO ------------------------ */}


                    <Grid container>

                    <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }}  >
                        <SubTitle> Endereçamento</SubTitle>
                      </Grid>



                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >


                        <InvisibleContent visible={formaContribBoleto === true || formaContribCredito === true || formaContribDebito === true}>


                          <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                            {/* { cep, uf, cidade } */}
                            <Grid container >
                              <Grid item lg={12} sm={12} xs={12}>
                                <Form.Item
                                  label="CEP *"
                                  name="cep"
                                  rules={[
                                    { required: true, message: '*Campo obrigatório' }
                                  ]}
                                >
                                  <MaskedInput
                                    mask="11111-111"
                                    onChange={(e: any) => {debounceCEP(e.target.value); setCampo8(1)}}
                                  />
                                </Form.Item>
                              </Grid>


                              <InvisibleContent visible={loadingCEP2 === true}>


                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Estado *"
                                    name="uf"
                                    rules={[{ required: true, message: "*Campo obrigatório" },
                                    { max: 2, message: "Somente sigla" }]}
                                  >
                                    <SelectAntd >
                                      {suggestionsEstado.map(item => (
                                        <SelectAntd.Option key={item.label} value={item.label}>
                                          {item.label}
                                        </SelectAntd.Option>
                                      ))}
                                    </SelectAntd>
                                  </Form.Item>
                                </Grid>


                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Cidade *"
                                    name="cidade"
                                    rules={[
                                      { required: true, message: "*Campo obrigatório" },
                                      {pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                                      message: 'Somente letras'}
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Grid>

                                {/* { cep, uf, cidade } */} {/* { endereço, numero } */}


                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Endereço *"
                                    name="descricao"
                                    rules={[{ required: true, message: "*Campo obrigatório" }]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                </Grid>


                                {/* { endereço, numero } */} {/* { complemento, bairro } */}
                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Número *"
                                    name="numero"
                                    rules={[{required: true,  message: "*Campo obrigatório"}]}
                                  >
                                    <Input type={"number"}
                                      onChange={() =>  setCampo9(1)}/>
                                  </Form.Item>
                                </Grid>


                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Complemento"
                                    name="complemento"
                                    rules={[{ max: 45, message: 'Máximo de 45 caracteres' },]}
                                  >
                                    <Input onInput={handleInput} />
                                  </Form.Item>
                                </Grid>


                                <Grid item lg={6} sm={6} xs={12}>
                                  <Form.Item
                                    label="Bairro *"
                                    name="bairro"
                                    rules={[{ required: false }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Grid>


                              </InvisibleContent>


                            </Grid>
                          </Spin>
                        </InvisibleContent>
                      </Grid>
                    </Grid>


                    {/* ------------------------ REALIZAR DOAÇÃO BOLETO ----------------------------- */}


                    <Grid container>

                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >

                        <InvisibleContent visible={formaContribBoleto === true}>
                          <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitBoleto}
                            >
                              <ButtonComponent
                                color="secondary"
                                loading={loadingCreatePessoaBoleto}
                                type="submit"
                                //onClick


                              >
                                Realizar doação
                              </ButtonComponent>
                            </Form>
                          </Grid>
                        </InvisibleContent>
                      </Grid>
                    </Grid>

                    {/* ------------------------ REALIZAR DOAÇÃO DÉBITO AUTOMÁTICO ----------------------------- */}

                    <Grid container>

                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >

                        <InvisibleContent visible={formaContribDebito === true}>
                          <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitDebito}
                            >
                              <ButtonComponent
                                color="secondary"
                                loading={loadingCreatePessoaDebito}
                                type="submit"
                                //onClick

                              >
                                Realizar doação
                              </ButtonComponent>
                            </Form>
                          </Grid>
                        </InvisibleContent>
                      </Grid>
                    </Grid>




                    {/* ------------------------ REALIZAR DOAÇÃO CARTÃO DE CRÉDITO ----------------------------- */}

                    <Grid container>

                      <Grid item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24 }} >

                        <InvisibleContent visible={formaContribCredito === true}>
                          <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                            <Form
                              form={formPessoaRef}
                              scrollToFirstError={true}
                              onFinish={onSubmitCredito}
                            >
                              <ButtonComponent
                                color="primary"
                                loading={loadingCreatePessoaCredito}
                                fullWidth
                                type="submit"
                              >
                                Realizar doação
                              </ButtonComponent>
                            </Form>
                          </Grid>
                        </InvisibleContent>
                      </Grid>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 10}} >
                      <Button
                        color='default'
                        onClick={() => HandleChangeEtapa(1)}
                        style={{color: '#000', fontSize: '16px', backgroundColor: 'transparent'}}
                       >
                          Voltar
                      </Button>
                    </Grid>

                  </InvisibleContent>
                </Grid>

              </Form>


              {/* --------------------------- RODAPÉ ----------------------------- */}


              <Grid container>
                <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 30 }} >

                  <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justify="center">


                    <LockOutlined style={{color:'#4CAF50', height: 18, marginTop: -42}} />

                    <Grid container item lg={11} md={11} sm={11} xs={11} alignItems="center" justify="center">
                      <p style={{fontSize: 17, color: '#333', textAlign:"center" }}>
                        Este é um ambiente seguro e suas informações estão seguras e protegidas.  Utilizamos criptografia de ponta a ponta.
                      </p>

                    </Grid>


                  </Grid>


                </Grid>
              </Grid>







              <Grid container>
                <Grid container alignItems="center" justify="center" item lg={12} sm={12} xs={12} style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 35 }} >




                  <PhoneOutlined style={{marginTop: 3, height: 17}}/>


                  <Grid container item lg={7} md={7} sm={7} xs={8} alignItems="center" justify="center" >

                      <a href="https://wa.me/556235069800" target="_blank" rel="noopener noreferrer">
                        <strong style={{fontSize: 17, color: '#333', textAlign:'center'}}>Central de Relacionamento: </strong>
                        <strong style={{textDecoration: 'underline',  textAlign:'center'}}>(62) 3506-9800</strong>
                      </a>

                  </Grid>




                </Grid>
              </Grid>


              {/* ------------------------------------------------------ */}

            </Grid>


            <Grid item lg={2} sm={1} xs={1} ></Grid>

          </Grid>

        </InvisibleContent>

      {/* --------------------------- AGRADECIMENTO ----------------------------- */}
        <InvisibleContent visible={openAgradecimento === true}>
          <Agradecimento/>
        </InvisibleContent>


        {/* --------------------------- RODAPÉ 2 ----------------------------- */}


        <Grid item lg={12} sm={12} xs={12} style={{paddingTop: 40}}>
          <Grid container alignItems="center" justify="center"
            style={{backgroundColor: '#fcf7f7', paddingTop: 10, paddingBottom: 10}}
          >
            <Grid container item lg={8} sm={8} xs={12} alignItems="center" justify="center" >
              <TitlePage style={{fontSize: 14, color:'#333'}}>Desenvolvido por  </TitlePage>
              <img src={LogoRodape} style={{paddingLeft:10, width: 140 }} />
            </Grid>
          </Grid>
        </Grid>

      </Grid>

    </>

  );
};

export default Principal;
