import styled from 'styled-components';

export const TitlePage = styled.h1`
  display: block;
  font-size: 2.5em;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  font-family: Arial, sans-serif
  color: #333;
`;


export const SubTitle = styled.h2`
  display: block;
  font-size: 1.9em;
  margin-block-start: 0.83em;
  margin-block-end: 0.0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  color: #333;
`;


export const InterTitle = styled.h3`
display: block;
font-size: 1.5em;
margin-block-start: 0.5em;
margin-block-end: 0em;
margin-inline-start: 0px;
margin-inline-end: 0px;
font-weight: bold;
unicode-bidi: isolate;
`;


export const RecorrenteTitle = styled.h3`
font-size: 1em;
margin-block-start: 0em;
margin-block-end: 0em;
margin-inline-start: 0px;
margin-inline-end: 0px;
font-weight: bold;
unicode-bidi: isolate;
`;
