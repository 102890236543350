import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { TitlePage, SubTitle, InterTitle} from '@styles/styles.global';
import { getColors } from '@styles/colorsTheme';


import '@styles/AntStyles.css';
import { CloseOutlined } from '@material-ui/icons';

import Logo from '@assets/Login/Logotipo_horizontal_principal.png'


const Agradecimento: React.FC = () => {

  function handleModalClose() {
    window.location.reload();
  }

  return (
    <>
      <Grid container>

        <Grid container item lg={12} sm={12} xs={12} alignItems="center" justify="center" style={{marginTop: 60, marginBottom: 115}}>

          <Grid container item lg={12} sm={12} xs={12}>
            <Grid  container item lg={3} sm={3} xs={12} alignItems="center" justify="center"></Grid>

            <Grid  container item lg={6} sm={6} xs={12} justify="center"  alignItems="center" style={{ backgroundColor:'#f1f1f166',}}>



                  <Grid  container item lg={12} sm={12} xs={12} justify='flex-end' style={{textAlign:'end', marginRight: 5,}}>
                      <button
                        style={{marginTop: 10, borderColor: 'transparent', backgroundColor: 'transparent'  }}
                        onClick={() => {
                          handleModalClose();
                        }}
                      >
                        <CloseOutlined
                          fontSize="medium"
                          htmlColor={getColors()?.cinzaEscuro}
                        />
                      </button>
                    </Grid>

                    <Grid container item lg={12} sm={12} xs={12}
                     alignItems="center" justify="center" style={{paddingTop: 20}}
                    >
                      <img src={Logo} style={{width: 200, height: 86}} />
                  </Grid>

                <Grid container >
                  <Grid container item lg={12} sm={12} xs={12}
                    alignItems="center" justify="center" style={{ paddingTop: 9}}
                  >
                    <InterTitle>Agradecemos a sua doação!</InterTitle>
                  </Grid>
                  </Grid>



                    <Grid  container item lg={12} sm={12} xs={12} justify="center">

                    </Grid>

                    <Grid  container item lg={2} sm={2} xs={12} justify="center"></Grid>

                    <Grid  container item lg={8} sm={8} xs={12} justify="center">

                      <p style={{textAlign:'center', color:'#333', fontSize: '17px', marginTop: 10}}>
                      Através da sua doação, conseguimos manter vivo o legado do trabalho social e da missão evangelizadora.
                      </p>

                      <p style={{textAlign:'center', color:'#333', fontSize: '14x', marginTop: 20}}>
                        O Santuário Pai Eterno convida você a visitar  nossa página das Obras Sociais e conhecer os projetos que beneficiamos com a sua ajuda!
                        </p>
                        <p style={{textAlign:'center', color:'#333', fontSize: '14x', marginBottom: 50}}>
                        <a href="https://www.paieterno.com.br/home-afipe/obras-sociais/" target="_blank" rel="noopener noreferrer" style={{fontSize: 14, color: '#333', textDecorationLine: 'underline'}}> www.paieterno.com.br/home-afipe/obras-sociais/</a>
                        </p>

                    </Grid>

                    <Grid  container item lg={2} sm={2} xs={12} justify="center"></Grid>

                  </Grid>

                  <Grid  container item lg={3} sm={3} xs={12} justify="center"></Grid>
                </Grid>
              </Grid>

      </Grid>

    </>

  );
};

export default Agradecimento;
